import { ButtonContained, ButtonOutline } from '@makinox/makinox-ui';
export default function Reset() {
    var reloadContainer = document.querySelector('.section-reload');
    var titleElement = document.createElement('h2');
    var wrapElement = document.createElement('div');
    var noElement = document.createElement('button');
    var yesElement = document.createElement('button');
    reloadContainer.style.display = 'flex';
    titleElement.innerText = 'Play again?';
    noElement.className = "".concat(ButtonOutline());
    noElement.innerText = 'No';
    yesElement.className = "".concat(ButtonContained());
    yesElement.innerText = 'Yes';
    yesElement.onclick = function () { return window.location.reload(); };
    wrapElement.className = 'flex justify-center';
    reloadContainer.appendChild(titleElement);
    wrapElement.appendChild(noElement);
    wrapElement.appendChild(yesElement);
    reloadContainer.appendChild(wrapElement);
}
